
input[type=checkbox]:checked + label>img {
    border: 1px solid #fff;
    box-shadow: 0 0 3px 3px #090;
}

input[type=checkbox] + label>img {
    border: 1px #444;
    transition: 100ms all;
}

.loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stakinginprogress{
    height: 250px;
}

label.staked {
    margin-bottom: 0;
}
